<template>
  <page-header-wrapper style="margin: unset;">
    <a-row :gutter='23'>
      <a-col :sm='24' :md='24' :xl='24' :style="{ marginBottom: '24px' }">
        <a-tabs default-active-key="1" :tabBarStyle="{background: '#fff'}" @change="getDataByMonth">
          <a-tab-pane key="1" :tab="'本月'">
            <a-card>
              <div class="card_title">
                <a-row>
                  <a-col :span="19">本月现金折扣：￥{{dashboard.lastCashTotal}}</a-col>
                  <a-col :span="4">受让：￥{{dashboard.lastCashRewardTransferTo}}</a-col>
                </a-row>
              </div>
              <div class="card_title" style="padding-top: 10px;"></div>
              <div class="card_title" style="line-height: 30px;">
                <a-row>
                  <a-col :span="2">订单1：</a-col>
                  <a-col :span="2">订单2：</a-col>
                  <a-col :span="2">订单3：</a-col>
                  <a-col :span="2">订单4：</a-col>
                  <a-col :span="2">订单5：</a-col>
                  <a-col :span="2">订单6：</a-col>
                  <a-col :span="2">修改：</a-col>
                  <a-col :span="2">活动：</a-col>
                  <a-col :span="3">现金补贴：{{dashboard.lastCashSubsidyCount}}笔</a-col>
                  <a-col :span="4">服务：￥{{dashboard.lastCashRewardCenter2}}</a-col>
                </a-row>
              </div>
              <div class="card_title">
                <a-row>
                  <a-col :span="2">￥{{dashboard.lastCashReward1}}</a-col>
                  <a-col :span="2">￥{{dashboard.lastCashReward2}}</a-col>
                  <a-col :span="2">￥{{dashboard.lastCashReward3}}</a-col>
                  <a-col :span="2">￥{{dashboard.lastCashReward4}}</a-col>
                  <a-col :span="2">￥{{dashboard.lastCashReward5}}</a-col>
                  <a-col :span="2">￥{{dashboard.lastCashReward6}}</a-col>
                  <a-col :span="2">￥{{dashboard.lastCashUpdate}}</a-col>
                  <a-col :span="2">￥{{dashboard.lastCashRewardActivity}}</a-col>
                  <a-col :span="3">￥{{dashboard.lastCashSubsidyAmount}}</a-col>
                  <a-col :span="4">运营：￥{{dashboard.lastCashRewardPresident2}}</a-col>
                </a-row>
              </div>
            </a-card>
          </a-tab-pane>
          <a-tab-pane key="2" :tab="'上月'">
            <a-card>
              <div class="card_title">
                <a-row>
                  <a-col :span="19">本月现金折扣：￥{{lastDashboard.lastCashTotal}}</a-col>
                  <a-col :span="4">受让：￥{{lastDashboard.lastCashRewardTransferTo}}</a-col>
                </a-row>
              </div>
              <div class="card_title" style="padding-top: 10px;"></div>
              <div class="card_title" style="line-height: 30px;">
                <a-row>
                  <a-col :span="2">订单1：</a-col>
                  <a-col :span="2">订单2：</a-col>
                  <a-col :span="2">订单3：</a-col>
                  <a-col :span="2">订单4：</a-col>
                  <a-col :span="2">订单5：</a-col>
                  <a-col :span="2">订单6：</a-col>
                  <a-col :span="2">修改：</a-col>
                  <a-col :span="2">活动：</a-col>
                  <a-col :span="3">现金补贴：{{lastDashboard.lastCashSubsidyCount}}笔</a-col>
                  <a-col :span="4">服务：￥{{lastDashboard.lastCashRewardCenter2}}</a-col>
                </a-row>
              </div>
              <div class="card_title">
                <a-row>
                  <a-col :span="2">￥{{lastDashboard.lastCashReward1}}</a-col>
                  <a-col :span="2">￥{{lastDashboard.lastCashReward2}}</a-col>
                  <a-col :span="2">￥{{lastDashboard.lastCashReward3}}</a-col>
                  <a-col :span="2">￥{{lastDashboard.lastCashReward4}}</a-col>
                  <a-col :span="2">￥{{lastDashboard.lastCashReward5}}</a-col>
                  <a-col :span="2">￥{{lastDashboard.lastCashReward6}}</a-col>
                  <a-col :span="2">￥{{lastDashboard.lastCashUpdate}}</a-col>
                  <a-col :span="2">￥{{lastDashboard.lastCashRewardActivity}}</a-col>
                  <a-col :span="3">￥{{lastDashboard.lastCashSubsidyAmount}}</a-col>
                  <a-col :span="4">运营：￥{{lastDashboard.lastCashRewardPresident2}}</a-col>
                </a-row>
              </div>
            </a-card>
          </a-tab-pane>
        </a-tabs>
<!--        <a-card>-->
<!--          <div class="card_title">-->
<!--            <a-row>-->
<!--              <a-col :span="19">本月现金折扣：￥{{dashboard.lastCashTotal}}</a-col>-->
<!--              <a-col :span="4">受让：￥{{dashboard.lastCashRewardTransferTo}}</a-col>-->
<!--            </a-row>-->
<!--          </div>-->
<!--          <div class="card_title" style="padding-top: 10px;"></div>-->
<!--          <div class="card_title" style="line-height: 30px;">-->
<!--            <a-row>-->
<!--              <a-col :span="2">订单1：</a-col>-->
<!--              <a-col :span="2">订单2：</a-col>-->
<!--              <a-col :span="2">订单3：</a-col>-->
<!--              <a-col :span="2">订单4：</a-col>-->
<!--              <a-col :span="2">订单5：</a-col>-->
<!--              <a-col :span="2">订单6：</a-col>-->
<!--              <a-col :span="2">修改：</a-col>-->
<!--              <a-col :span="2">活动：</a-col>-->
<!--              <a-col :span="3">现金补贴：</a-col>-->
<!--              <a-col :span="4">服务：￥{{dashboard.lastCashRewardCenter2}}</a-col>-->
<!--            </a-row>-->
<!--          </div>-->
<!--          <div class="card_title">-->
<!--            <a-row>-->
<!--              <a-col :span="2">￥{{dashboard.lastCashReward1}}</a-col>-->
<!--              <a-col :span="2">￥{{dashboard.lastCashReward2}}</a-col>-->
<!--              <a-col :span="2">￥{{dashboard.lastCashReward3}}</a-col>-->
<!--              <a-col :span="2">￥{{dashboard.lastCashReward4}}</a-col>-->
<!--              <a-col :span="2">￥{{dashboard.lastCashReward5}}</a-col>-->
<!--              <a-col :span="2">￥{{dashboard.lastCashReward6}}</a-col>-->
<!--              <a-col :span="2">￥{{dashboard.lastCashUpdate}}</a-col>-->
<!--              <a-col :span="2">￥{{dashboard.lastCashRewardActivity}}</a-col>-->
<!--              <a-col :span="3">￥4800</a-col>-->
<!--              <a-col :span="4">运营：￥{{dashboard.lastCashRewardPresident2}}</a-col>-->
<!--            </a-row>-->
<!--          </div>-->
<!--        </a-card>-->
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='memberType'>
        <a-select v-model="form.memberType" placeholder="用户类型" style="width: 250px">
          <a-select-option :value="undefined">
            用户类型
          </a-select-option>
          <a-select-option value="president">
            1
          </a-select-option>
          <a-select-option value="center">
            2
          </a-select-option>
          <a-select-option value="store">
            3
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='type'>
        <a-select v-model="form.type" placeholder="变动类型" style="width: 250px">
          <a-select-option :value="undefined">
            变动类型
          </a-select-option>
          <a-select-option :value="1">
            使用
          </a-select-option>
          <a-select-option :value="2">
            修改
          </a-select-option>
          <a-select-option :value="3">
            奖励
          </a-select-option>
          <a-select-option :value="4">
            兑换
          </a-select-option>
          <a-select-option :value="5">
            转让
          </a-select-option>
          <a-select-option :value="6">
            受让
          </a-select-option>
          <a-select-option :value="7">
            退回
          </a-select-option>
          <a-select-option :value="8">
            活动奖励
          </a-select-option>
          <a-select-option :value="9">
            房租补贴
          </a-select-option>
          <a-select-option :value="10">
            门票
          </a-select-option>
          <a-select-option :value="13">
            商户删除
          </a-select-option>
          <a-select-option :value="14">
            获得
          </a-select-option>
          <a-select-option :value="15">
            推广
          </a-select-option>
          <a-select-option :value="16">
            推广+活动
          </a-select-option>
          <a-select-option :value="19">
            奖励积分
          </a-select-option>
          <a-select-option :value="21">
            批量兑换
          </a-select-option>
          <a-select-option :value="22">
            批量兑换退回
          </a-select-option>
          <a-select-option :value="23">
            解除合同
          </a-select-option>
          <a-select-option :value="26">
            业绩5%
          </a-select-option>
          <a-select-option :value="17">
            业绩2%
          </a-select-option>
          <a-select-option :value="24">
            业绩1.5%
          </a-select-option>
          <a-select-option :value="25">
            业绩1-1%
          </a-select-option>
          <a-select-option :value="27">
            业绩1%
          </a-select-option>
          <a-select-option :value="28">
            业绩10%
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='endTag'>
        <a-select v-model="form.endTag" placeholder="订单尾号" style="width: 250px">
          <a-select-option value="-1">
            全部
          </a-select-option>
          <a-select-option v-for="i in 7" :key="i" :value="i">
            {{i}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 300px" placeholder="姓名/手机号" />
      </a-form-model-item>
      <!-- <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item> -->
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <span style="margin-left: 10px;margin-right: 10px;"> 笔数：{{this.total}}</span>
        <span> 变动值：{{this.ext4}}</span>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='memberType' slot-scope='text'>
          <template>
            {{ text == "store" ? "3" : text == "center" ? "2" : text == "president" ? "1" :  "" }}
          </template>
        </span>
        <span slot='businessContent' slot-scope='text, record'>
          <template>
            {{ (record.afterBalance - record.beforeBalance) | parseMoney }}
          </template>
        </span>
        <span slot='orderId' slot-scope='text, record'>
          <template>
            {{showText(record)}}
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { getAuditOrderData, queryCash } from '@/api/member-order'
import moment from 'moment'
import { getUserTypeName } from '@/utils/util'


const columns = [
  {
    width: 100,
    title: '用户类型',
    dataIndex: 'memberType',
    scopedSlots: { customRender: 'memberType' }
  },
  {
    width: 100,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 120,
    title: '变动值',
    dataIndex: 'businessContent',
    scopedSlots: { customRender: 'businessContent' }
  },
  {
    width: 100,
    title: '变动后数值',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 200,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 200,
    title: '时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 180,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' }
  },
  {
    width: 150,
    title: '姓名',
    dataIndex: 'userName',
    scopedSlots: { customRender: 'userName' }
  },
  {
    width: 150,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'MonthCashGetList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        keyword: "",
        start: moment(baseStart).startOf('day').format(formatType + " HH:mm"),
        end: moment(baseEnd).endOf('day').format(formatType+ " HH:mm"),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      lastDashboard: {}, //上月订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {
        recordType: 1
      },
      total:0,
      ext4:0,
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        return queryCash(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            debugger;
            this.total = datum.total;
            this.ext4 = datum.ext4;
            return datum
          })
      },
    }
  },
  created() {
    this.getOrderData();
  },
  methods: {
    showText(record){
      // debugger;
      let text = "";
      if(record.recordType===2|| record.recordType === 9){
        text = record.opUsername;
      }else if(record.recordType === 6){
        text = record.transferFromUserName+"转";
      }else if(record.recordType === 5){
        text = record.transferToUserName+"得";
      }else{
        text = record.orderId;
      }
      return text;
    },
    moment,
    getUserTypeName,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType + " HH:mm");
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType+ " HH:mm");
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      if(this.form.endTag === "-1"){
        delete this.form.endTag;
      }
      this.getOrderData();
      this.$refs.table.refresh(true)
    },
    getOrderData() {
      getAuditOrderData(Object.assign({}, this.form)).then(result => {
        result.lastCashReward1 = result.lastCashReward1 || 0;
        result.lastCashReward2 = result.lastCashReward2 || 0;
        result.lastCashReward3 = result.lastCashReward3 || 0;
        result.lastCashUpdate = result.lastCashUpdate || 0;
        // result.lastCashRewardTotal = result.lastCashReward1 + result.lastCashReward2 + result.lastCashReward3 + result.lastCashUpdate;
        // result.lastCashRewardTotal = parseFloat(result.lastCashRewardTotal.toFixed(2));
        this.$emit("setGetTitle", result.lastCashTotal);
        result.lastCashUsed1 = result.lastCashUsed1 || 0;
        result.lastCashUpdateUsed = result.lastCashUpdateUsed || 0;
        result.lastCashActivityUsed = result.lastCashActivityUsed || 0;
        // result.lastCashUsedTotal = result.lastCashUsed1 + result.lastCashUpdateUsed + result.lastCashActivityUsed;
        // result.lastCashUsedTotal = parseFloat(result.lastCashUsedTotal.toFixed(2));
        result.lastCashSubsidyCount = result.lastCashSubsidyCount||0;
        result.lastCashSubsidyAmount = result.lastCashSubsidyAmount||0;
        this.$emit("setUseTitle", result.lastCashUsedTotal);

        this.dashboard = result;
        this.loading = false;
      })

      //上月
      let lastMonth = this.getLastMonth();
      getAuditOrderData(Object.assign({month:lastMonth}, this.form)).then(result => {
          result.lastCashReward1 = result.lastCashReward1 || 0;
          result.lastCashReward2 = result.lastCashReward2 || 0;
          result.lastCashReward3 = result.lastCashReward3 || 0;
          result.lastCashUpdate = result.lastCashUpdate || 0;

          result.lastCashUsed1 = result.lastCashUsed1 || 0;
          result.lastCashUpdateUsed = result.lastCashUpdateUsed || 0;
          result.lastCashActivityUsed = result.lastCashActivityUsed || 0;

          result.lastCashSubsidyCount = result.lastCashSubsidyCount||0;
          result.lastCashSubsidyAmount = result.lastCashSubsidyAmount||0;
          this.lastDashboard = result;
      });

    },
    getLastMonth() {
      let year, lastMonth;
      let date = new Date();
      let nowYear = date.getFullYear();   //当前年：四位数字
      let nowMonth = date.getMonth();     //当前月：0-11
      if (nowMonth === 0) {   //如果是0，则说明是1月份，上一个月就是去年的12月
        year = nowYear - 1;
        lastMonth = 12;
      } else { //不是1月份，年份为当前年，月份本来是要减1的，但是由于`getMonth()`的月份本身就是少了1的，所以月份不用变。
        year = nowYear;
        lastMonth = nowMonth;
      }
      lastMonth = lastMonth < 10 ? ('0' + lastMonth) : lastMonth;   //月份格式化：月份小于10则追加个0
      return year + '-' + lastMonth;
    },
    getMonthFinalDay(year,month){
      let day='';
      if(year == null || year===''){
        year = new Date().getFullYear();
      }
      if(month == null || month===''){
        month = new Date().getMonth()+1;
      }
      day = new Date(new Date(year,month).setDate(0)).getDate();
      return year+"-"+month+"-"+day;

    },
    getDataByMonth(tabs){
      let start = "";
      let end = "";
      if(tabs === "1"){
        //本月
        let year = new Date().getFullYear();
        let month = new Date().getMonth()+1;
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }else{
        //上月
        let lastMonth = this.getLastMonth();
        let monthYear = lastMonth.split("-");
        let year = monthYear[0];
        let month = monthYear[1];
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }
      end = end + " 23:59";
      start = start + " 00:00";
      console.log(start + " ## " +end);
      this.form.start = start;
      this.form.end = end;
      this.handleSubmit();

    }
  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
}
.order_count .card_content {
  width: 50%;
}
/deep/.ant-page-header{
  display: none;
}
</style>
